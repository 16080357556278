import {
  del,
  file,
  get, post, put, remove,
} from './http-service'

export const getDoctorProfile = async (token, tokenData) => {
    // doctor login
    const response = await getDoctorsList()
    if (response.status == 200) {
        const user = response.data.find(user => user.userId == token.uid)
        return { ...user, token: tokenData[1], isAdmin: false }
    }
    return null
}

export const loginApi = (data) => post('/api/auth/sign-in', data)

export const logoutApi = () => post('/api/auth/sign-out', '', true)

export const getVisitApi = (params) => get('/api/visit', true, params)

export const visitDetailsApi = (id) => get(`/api/visit/${id}`, true)

export const closeVisitApi = (id) => put(`/api/visit/${id}`, id, true)

export const patientDetailsApi = (id) => get(`/api/patient/${id}`, true)

export const userListApi = (params) => get('/api/user', true, params)

export const queryStringUser = "USER"

export const fetchUsers = async (doctors, visitorId) => {
  const response = await userListApi({ withPatients: true, withVisits: true })
  const users = response.data || []
  const sorted = users.map(user => {
    const doctor = doctors.find(doc => {
      return doc.id === +(user.profile.data.doctor)
    }) || { id: -1 }
    return {
      ...user,
      doctor: doctor,
      order: doctor.id === visitorId ? 0 : 1
    }
  }).sort((a, b) => a.order > b.order ? 1 : -1)
  return sorted
}

export const fetchPatients = async () => {
  const response = await userListApi({ withPatients: true })
  const users = response.data || []
  return users.filter(item => item.roles.includes(queryStringUser)).flatMap(u => u.patients)
}

export const getMediaApi = (id) => file(`/api/media/${id}/file`, true)

export const getExamTypeApi = (id) => get(`/api/exam-type/${id}`, true)

export const getExamApi = (id) => get(`/api/exam/${id}?grouped=true`, true)

export const getAllExams = (patientId) => get('/api/exam', true)

export const addExam = (data) => post('/api/exam', data, true)

export const addExamData = (data) => post('/api/exam-data', data, true)

export const getExamsByPatient = (patientId) => get(`/api/exam?byPatient=${patientId}`, true)

export const editProfileApi = (id, data) => put(`/api/profile/${id}?merge=true&withDate=true`, data, true)

export const editProfileWithoutMergeApi = (id, data) => put(`/api/profile/${id}?merge=false&withDate=true`, data, true)

export const openNewVisitApi = (data) => post(`/api/visit`, data, true)

export const getPatientListApi = (userId) => get(`/api/patient?userId=${userId}`, true)

export const addPatient = (userId, data) => post(`/api/patient?userId=${userId}`, data, true)

export const getExamTypeListApi = () => get('/api/exam-type', true)

export const getUserDetails = (id) => get(`/api/user/${id}`, true)

export const deleteUserApi = (id) => remove(`/api/user/${id}`, true)

export const closeExamApi = (id) => remove(`/api/exam/${id}/close`, true)

export const acceptPointApi = (id) => put(`/api/exam-data/${id}/accept`, id, true)

export const addUserApi = (data) => post(`/api/user`, data, true)

export const addUserWebPushSubscription = (id, data) => put(`/api/user/${id}/web-push`, data, true)

export const deletePatientApi = (id) => remove(`/api/patient/${id}`, true)

export const postApiDocument = (data) => post('/api/document', data, true)

export const editUserApi = (id, data) => put(`/api/user/${id}`, data, true)

export const getDoctorsList = () => get(`/api/doctor`, true)

export const editDoctor = (id, data) => put(`/api/doctor/${id}`, data, true)

export const getDoctorDetails = (id) => get(`/api/doctor/${id}`, true)
/**
 * @typedef { object } Group
 * @property { number } id
 * @property { string } name
 * @property { string } description
 */
/**
 * 
 * @returns { Promise<import('axios').AxiosResponse<Group[]>> } groups list
 */
export const getDoctorsGroupsList = () => get(`/api/doctor/groups`, true)
/**
 * @param { Group } data group data
 * @returns { Promise<import('axios').AxiosResponse<Group>> } created group
 */
export const addGroup = (data) => post(`/api/doctor/groups`, data, true)
/**
 * @param { number } id group id
 * @param { Group } data group data
 * @returns { Promise<import('axios').AxiosResponse<Group>> } edited group
 */
export const editGroup = (id, data) => put(`/api/doctor/groups/${id}`, data, true)
/**
 * @param { number } id group id
 * @returns { Promise<import('axios').AxiosResponse> } deletion status
 */
export const deleteGroup = (id) => { return del(`/api/doctor/groups/${id}`, true) }

export const getDiariesApi = (visitId) => get(`/api/diary?visitId=${visitId}`, true)

export const addDocumentApi = (data) => post(`/api/document`, data, true)

export const editPatientApi = (id, data) => put(`/api/patient/${id}`, data, true)

export const getQuestionnaireList = (questionType, examType) => {
  const urlParams = new URLSearchParams()
  urlParams.set("type", questionType)
  if (examType) {
    urlParams.set("examType", examType)
  }
  return get(`/api/questionnaire/quest?${urlParams.toString()}`, true) 
}

export const getQuestionnaireItem = (questionId) => get(`/api/questionnaire/quest/${questionId}`, true)

export const addQuestionnaireApi = (data) => post(`/api/questionnaire/quest`, data, true)

export const updateQuestionnaireApi = (id, data) => put(`/api/questionnaire/quest/${id}`, data, true)

export const deleteQuestionnaireApi = (id) => remove(`/api/questionnaire/quest/${id}`, true)

export const deleteDoctorApi = (id) => remove(`/api/doctor/${id}`, true)

export const getMediaInfoApi = (id) => get(`/api/media/${id}/refresh`, true)

export const deleteMediaApi = (id) => remove(`/api/media/${id}`, true)

export const getExamListByVisitApi = (visitId) => get(`/api/exam?byVisit=${visitId}`, true)

export const documentTypeApi = () => get(`/api/document-type`, true)

export const getListOfProfiles = (patientId) => get(`/api/profile?byPatient=${patientId}&split=true&type=VISIT`, true)

export const getDocumentList = (patientId) => get(`/api/document?byPatient=${patientId}`, true)

export const getDocumentListFiltered = (patientId, from, to) => get(`/api/document?byPatient=${patientId}&from=${from}&to=${to}`, true)

export const getVaccinationListApi = (patientId) => get(`/api/vaccination?patientId=${patientId}`, true)

export const addVaccineApi = (data) => post(`/api/vaccination`, data, true)

export const deleteVaccineApi = (id) => remove(`/api/vaccination/${id}`, true)

export const getVaccineDetailsApi = (id) => get(`/api/vaccination/${id}`, true)

export const editVaccineApi = (id, data) => put(`/api/vaccination/${id}`, data, true)

export const eulaAccept = () => post(`/api/auth/accept`, null, true)

export const isReadClaim = (id) => put(`/api/questionnaire/quest/isread/${id}`, null, true)

export const uploadDocumentApi = (id, file, params) => {
  const formData = new FormData()
  formData.append("file", file)
  if (params) {
    for (var key in params) {
      formData.append(key, params[key])
    }
  }
  return post(`/api/media/${id}`, formData, true)
}

