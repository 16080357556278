import { addUserWebPushSubscription } from "../services/http-service/api"

export const WEBPUSH_PUBLIC_KEY = "BO0xPYBrZsVVq7N1ubH6cy7PCIGoynem7sU79s_2Z6-JOQ0eBePS61jFauKhPsbYDsbVPH1cozgBIqDAJB_HIIw"

export const registerServiceWorker = (visitor) => {
  if (typeof navigator.serviceWorker !== 'undefined') {
    navigator.serviceWorker.register(new URL("../sw.js", import.meta.url), { scope: '/' })
    navigator.serviceWorker.ready.then(reg => {
      if (visitor && visitor.userId && hasNotificationPermissions()) {
        reg.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: WEBPUSH_PUBLIC_KEY
        }).then(
          sub => {
            addUserWebPushSubscription(visitor.userId, sub).then((response) => {
              console.log(response.status)
            })
          },
          err => console.error(err)
        )
      }
    })
  }
}

export const hasNotificationPermissions = () => {
  return Notification.permission.toLowerCase() === 'granted'
}

export const permissionStatus = () => {
  if ('Notification' in window) {
    switch (Notification.permission.toLowerCase()) {
      case 'granted':
        return "Подключено"
      case 'denied':
        return "Запрещено"
      case 'default':
        return "Подключить"
    }
  }
  return "-"
}
