import { lazy } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { VisitorProvider } from './hooks/use-visitor'
import { getUserFromToken } from './helpers/profile'
import { ConfigProvider } from 'antd'


const App = lazy(() => import('./App'))

function ready(fn) {
  if (document.readyState != 'loading') {
    fn()
  } else {
    document.addEventListener('DOMContentLoaded', fn)
  }
}

ready(async () => {
  const root = createRoot(document.getElementById('root'))
  // "Bearer XXXXX"
  const storedToken = localStorage.getItem('token')
  const visitor = await getUserFromToken(storedToken)
  root.render((
    <ConfigProvider renderEmpty={() => (<h3>Не найдено</h3>)}>
      <VisitorProvider initialUser={visitor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </VisitorProvider>
    </ConfigProvider>
  ))
})
