import { jwtDecode } from "jwt-decode"
import { getDoctorProfile } from "../services/http-service/api"

export const getGender = (patient) => {
    return patient &&
    patient.profile &&
    (/^male$/i).test(patient.profile.data.gender)
    ? "Мужской"
    : patient &&
      patient.profile &&
      (/^female$/i).test(patient.profile.data.gender) ? "Женский" : ""
}

export const getUserFromToken = async (storedToken) => {
    const tokenData = storedToken && storedToken.split(" ", 2)
    let auth
    if (tokenData && tokenData.length == 2) {
        const token = jwtDecode(tokenData[1])
        if (token.role === 2) {
            auth = await getDoctorProfile(token, tokenData)
        } else {
            // root login
            auth = { ...token, token: tokenData[1], id: token.uid, isAdmin: token.role === 0 }
        }
    }
    return auth
}

export const examStatus = (exam) => {
    let status = exam.profile?.data?.external_id ? "DAMUMED": exam.status
    switch (status) {
        case 'OPEN':
            return exam.byDoctor ? "Назначено" : "Направлено доктору"
        case 'DAMUMED':
            return 'Направлено в Damumed'
        case 'CLOSED':
            return 'Получен'
        case 'ACTIVE':
            return 'Выполняется'
        case 'INACTIVE':
            return 'Пройдено'
        case 'UPLOADING':
            return 'В процессе выгрузки'
    }
}

export const aiStatus = (point) => {
    const pointData = JSON.parse(point && point.media?.profile?.data?.external_data || "{}")
    console.log(pointData)
    const description = pointData.childs && pointData.childs.length > 0 && pointData.childs[0].user_type_desc ? 
    (pointData.childs[0].user_type_desc["ru"] || "Не определено")
    : "Не получено"
    if (pointData.childs && pointData.childs.length > 0 && pointData.childs[0].ai_check) {
        const aiCheck = pointData.childs[0].ai_check
        if (aiCheck.data && aiCheck.data.length > 0) {
            return aiCheck.data.some((el) => el.result.aiResult.pathologyFlag)? `ПАТОЛОГИЯ ${description}` : `НЕТ патологии ${description}`
        }
    }
    return description
}
