import axios from 'axios'

if(window.location.pathname === '/login') {
  const token = localStorage.getItem('token')
  if(token !== null) {
    localStorage.clear()
  }
}

const defaultConfig = {
}

const getConfig = (auth) => {
  if (!auth) {
    return defaultConfig
  }

  return {
    ...defaultConfig,
    headers: {
      ...defaultConfig.headers,
      Authorization: localStorage.getItem('token'),
    },
  }
}

const onSuccess = res => ({ data: res.data, headers: res.headers, status: res.status })
const onError = err => ({ data: null, headers: err.headers, error: { status: err.status }})

axios.interceptors.response.use(response => response, error => {
  if (error.status === 403) {
    localStorage.clear()
    if (window.location.pathname !== '/login') {
      window.location.href = '/login'
    }
  } else {
    return error
  }
})

export const get = (url, auth, params) =>
  axios.get(url, { ...getConfig(auth), params }).then(onSuccess).catch(onError)

export const post = (url, data, auth) =>
  axios.post(url, data, getConfig(auth)).then(onSuccess).catch(onError)

export const postForm = (url, data, auth) =>
  axios.post(url, data, getConfig(auth)).then(onSuccess).catch(onError)

export const put = (url, data, auth) =>
  axios.put(url, data, getConfig(auth)).then(onSuccess).catch(onError)

export const remove = (url, auth) =>
  axios.delete(url, getConfig(auth)).then(onSuccess).catch(onError)

export const del = (url, auth) => { return axios.delete(url, getConfig(auth)) }

export const file = (url) =>
  axios.get(url, {
    headers: {
      'Accept': 'application/octet-stream',
      'Content-Type': 'application/octet-stream',
      'Authorization': localStorage.getItem('token'),
    },
    responseType: 'blob',
  })
