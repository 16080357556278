import { createContext, useContext, useState } from 'react'
import { registerServiceWorker } from '../components/NotificationManager'

const Visitor = createContext()

/**
 * @param { import('react').PropsWithChildren<{}> } props
 */
export function VisitorProvider({initialUser, children }) {
    const state = useState(initialUser)
    registerServiceWorker(initialUser)
    return <Visitor.Provider value={state}>{children}</Visitor.Provider>
}

/**
 * @typedef {object} Profile
 * @typedef {object} data
 */

/**
 * @typedef {object} User
 * @property {string} fullName
 * @property {string} userId
 * @property {string[]} roles
 * @property {Profile} profile
 */

/**
 * @typedef {User} Visitor
 * @property {string} token
 * @property {number} id
 * @property {boolean} isAdmin
 */

/**
 * Visitor hook
 * 
 * @returns {[
 *  Visitor, 
 *  import('react').Dispatch<import('react').SetStateAction<object>>
 * ]} visitor hook
 */
export function useVisitor() {
    const visitor = useContext(Visitor)
    if (visitor === undefined) {
        throw new Error('useVisitor must be used within a VisitorProvider')
    }
    return visitor
}
